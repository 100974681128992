angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.configuration_services', {
            url: '/configuration/services',
            views: {
                "main": {
                    controller: 'ServicesCtrl',
                    templateUrl: 'web/main/configuration/services/services.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                order: (orderService) => orderService.getActualOrder(),
                nextOrder: orderService => orderService.getNext(),
                users: userService => userService.getUsuarios()
            },
            data: {
                pageTitle: 'Services'
            }
        });
    })

    .controller('ServicesCtrl', function ($scope, $q, session, orderService, userService, nextOrder, users, order, newLicenseModal, swalService) {
        var that = this;

        var init = (users, order, nextOrder) => {

            that.users = users;
            that.order = order;
            that.nextOrder = nextOrder;
            that.user = session.get().user;
            that.empresa = session.get().user.company;
            that.usersMap = _.keyBy(that.users, u => u.id);
            that.iniciales = (name, surname) => (name + (surname ? ' ' + surname : '')).split(' ').map(a => a[0]).reduce((a, b) => a + b);
        };
        init(users.data, order.data, nextOrder.data);

        $scope.$on("sessionChanged", () => $q.all([ userService.getUsuarios(), orderService.getActualOrder(), orderService.getNext()]).then(r => init.apply(null, _.map(r, i => i.data))));


        that.newLicense = () => newLicenseModal.openModal();

        that.momentUnity = {
            'MONTH': 'months',
            'YEAR': 'days',
            'DAY': 'years',
        };

        that.deleteNext = function () {
            swalService.requestSwal('¿Cancelar pedido?', 'Se va a cancelar el pedido', 'info', () => orderService.deleteNext()).then(
                response => {
                    swalService.basicSwal('¡Correcto!', 'Se ha cancelado correctamente', 'success');
                    orderService.getNext().then(response => that.nextPedidos = response.data);
                },
                response => swalService.basicSwal('Algo ha ido mal', 'Ha habido un error cancelando el pedido', 'error')
            );
        };
    });
